// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import router from "@/router";

export default function useTeamApi() {
    // Use toast
    const toast = useToast()
    // Table Handlers
    const refTeamListTable = ref(null)
    const perPage = ref(localStorage.getItem('teamPerPage') ?? 10)
    const totalTeams = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 15, 20, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const isLoading = ref(true)
    const teamData = ref(null)

    const tableColumns = [
        {key: 'id', sortable: true},
        {key: 'name', sortable: true},
        {key: 'is_active', label: 'Status', sortable: true},
        {key: 'created_at', sortable: true},
        {key: 'actions'},
    ]

    const statusOptions = [
        {label: 'Active', value: 1},
        {label: 'Passive', value: 0},
    ]

    const dataMeta = computed(() => {
        const localItemsCount = refTeamListTable.value ? refTeamListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalTeams.value,
        }
    })

    const refreshData = () => {
        localStorage.setItem('teamPerPage', perPage.value)
        refTeamListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refreshData()
    })


    // ------------------------------------------------
    // Get Team List
    // ------------------------------------------------
    const fetchTeams = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store
            .dispatch('teamStore/fetchTeams', {
                sort: sort,
                page: currentPage.value,
                perPage: perPage.value,
                q: searchQuery.value,
                pagination: true
            })
            .then(response => {
                const {data, total} = response.data
                callback(data)
                totalTeams.value = response.data.pagination.total
            })
            .catch(error => {
                console.log(error)
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchTeam = (ctx, callback) => {
        store
            .dispatch('teamStore/fetchTeam', {id: router.currentRoute.params.team})
            .then(response => {
                const {data} = response.data
                teamData.value = data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
                router.push('/team/list');
            })
    }


    return {
        fetchTeams,
        fetchTeam,
        teamData,
        tableColumns,
        perPage,
        currentPage,
        totalTeams,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTeamListTable,
        isLoading,
        refreshData,
        statusOptions
    }
}
