<template>
    <b-sidebar id="edit-team-sidebar" :visible="isEditTeamSidebarActive" bg-variant="white"
               sidebar-class="sidebar-lg" shadow backdrop no-header right
               @change="(val) => $emit('update:is-edit-team-sidebar-active', val)">
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Edit Team</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="refFormObserver" v-if="teamData">
                <b-form ref="form" class="p-2" @submit.prevent="handleSubmit(onSubmit)">
                    <validation-provider #default="{ errors }" name="Name" rules="required">
                        <b-form-group label="Name" label-for="name">
                            <b-form-input id="name" v-model="teamData.name" autofocus trim
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="John Doe"/>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="DiscordWebhook" rules="required">
                        <b-form-group label="Discord Webhook" label-for="DiscordWebhook">
                            <b-form-input id="DiscordWebhook" v-model="teamData.discord_webhook"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="" trim/>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="AsanaTeamId" rules="required">
                        <b-form-group label="Asana Team ID" label-for="AsanaTeamId">
                            <b-form-input id="AsanaTeamId" v-model="teamData.asana_team_id"
                                          :state="errors.length > 0 ? false:null"
                                          placeholder="" trim/>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="Status" rules="required">
                        <b-form-group label="Status" label-for="name" :state="errors.length > 0 ? false:null">

                            <v-select id="status" v-model="teamData.is_active"
                                      :options="statusOptions" :reduce="(option) => option.value"
                                      label="label" placeholder="Select Status" class="w-100">
                            </v-select>

                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                                  type="submit">
                            Update
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                                  variant="outline-secondary" @click="hide">
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useTeamApi from '@/composables/useTeamApi'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isEditTeamSidebarActive',
        event: 'update:is-edit-team-sidebar-active',
    },
    props: {
        isEditTeamSidebarActive: {
            type: Boolean,
            required: true,
        },
        teamData: {}
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('teamStore/updateTeam', {
                id: this.teamData.id,
                name: this.teamData.name,
                discord_webhook: this.teamData.discord_webhook,
                asana_team_id: this.teamData.asana_team_id,
                is_active: this.teamData.is_active
            }).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Successfully edited!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }).then(() => {
                this.$emit('refresh-data')
                this.$emit('update:is-edit-team-sidebar-active', false)

            }).catch((errors) => {
                console.log(errors)
            })
        }
    },
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const {
            fetchTeams,
            statusOptions
        } = useTeamApi()

        const {
            refFormObserver,
            getValidationState,
        } = formValidation()

        return {
            refFormObserver,
            getValidationState,

            statusOptions,
            fetchTeams,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
